<template>
    <div class="p-1">
        
        <v-row> 
            <v-col cols="12" class="d-flex justify-content-between">
                <div class="d-flex ml-3 p-2">
                    <div class="search">
                        <input type="text" class="searchTerm" v-model="searchValue"  :placeholder="$t('pages.crm.customers.sections.customers.table_header.search_text')">
                        <button type="submit" @click="searchConfirm()" class="searchButton">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="d-flex ml-3 pt-2" style="width:50%">
                    <div class="display-1 font-weight-light mr-3">{{$t('pages.crm.customers.sections.customers.section_title')}}</div>
                    <div class="data-picker" style="width:50%">
                        <ui-select  placeholder="" :options="groupe_liste" v-model="curent_groupe"   @change="loadData(curent_groupe)"></ui-select>
                    </div>
                </div>
                <div>
                    <el-button type="primary" icon="el-icon-plus" @click="handleAddUser()" style="border-radius:0px;">{{$t('pages.crm.customers.sections.customers.add-customers')}}</el-button>
                    <el-button type="primary" icon="el-icon-refresh" @click="handleRefresh()" style="border-radius:0px;">{{$t('pages.crm.customers.sections.customers.refresh')}}</el-button>
                </div>

            </v-col>
        </v-row>
        
        <el-divider class="mb-1"></el-divider>
        <v-row class="d-flex mb-2">
            <v-col :cols="tableCols">
                <el-skeleton :rows="20" animated v-if="loading" />
                <div v-else>
                    <ve-table :columns="columns" :table-data="tableData" :border-around="true" max-height="65vh" :border-x="true" :border-y="false"></ve-table>
                </div>

            </v-col>
            <v-col v-if="showAdd" style="height: 58vh" >
                <div class="card mb-3 shadow-sm" style="border-radius:0px; height: 100%">
                    <div class="card-header d-flex justify-content-between">
                        <div class="subtitle-1 font-weight-light" >{{title}}</div>
                        <v-icon aria-label="Close" @click="handleCloseAdd()" >mdi-close</v-icon>
                    </div>
                    <div class="card-body" style="overflow: scroll;">
                        <AddCustomers v-bind:form="form" v-bind:type="type" v-bind:loadData="loadData" class="mb-3 p-0"/>
                    </div>
                </div>
            </v-col>
        </v-row>

    </div>

</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';

    import AddCustomers from '@/components/dashboard/crm/customers/AddCustomers.vue';


    export default {

        components: {
            AddCustomers,
        },
        
        data() {
            return {

                loading: true,

                groupe_liste: [
                ],
                curent_groupe : '',
                all_data: [],
                tableData: [],

                showAdd: false,
                tableCols: 12,

                titleAdd : this.$t('pages.crm.customers.sections.customers.add.title'),
                titleEdit : this.$t('pages.crm.customers.sections.customers.edit.title'),
                title: '',
                type: 1,
                form: {
                    id  : "",
                    name  : "",
                    surname : "",
                    username : "",
                    service : "",
                    function : "",
                    profile : "",
                    password : "",
                    email : "",
                    sexe : "",
                    code_postal : "",
                    agence_id : null,
                    rue : "",
                    ville : "",
                    quartier : "",
                    phone : null,
                    phone_p : null,
                    date_naiss : null
                }, 
                
                searchValue: "",    
                columns: [
                    {
                        field: "",
                        key: "a",
                        title: "n°",
                        width: 50,
                        align: "center",
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return ++rowIndex;
                        },
                    },
                    {
                        field: "name",
                        key: "b",
                        title: this.$t('pages.crm.customers.sections.customers.table_header.name'),
                        align: "left",
                        // filter custom
                        // filterCustom: {
                        //     defaultVisible: true,
                        //     render: ({ showFn, closeFn }, h) => {
                        //         return (
                        //             <div class="custom-name-filter">
                        //                 <input
                        //                     value={this.searchValue}
                        //                     on-input={(e) => (this.searchValue = e.target.value)}
                        //                     placeholder="Search name"
                        //                 />
                        //                 <div class="custom-name-filter-operation">
                        //                     <span
                        //                         class="name-filter-cancel"
                        //                         on-click={() => this.searchCancel(closeFn)}
                        //                     >
                        //                         Cancel
                        //                     </span>
                        //                     <span
                        //                         class="name-filter-confirm"
                        //                         on-click={() => this.searchConfirm(closeFn)}
                        //                     >
                        //                         Search
                        //                     </span>
                        //                 </div>
                        //             </div>
                        //         );
                        //     },
                        // },

                    },
                    {
                        field: "function",
                        key: "c",
                        title: this.$t('pages.crm.customers.sections.customers.table_header.types'),
                        align: "left",
                    },
                    {
                        field: "ville",
                        key: "d",
                        title: this.$t('pages.crm.customers.sections.customers.table_header.towns'),
                        align: "center",
                    },
                    { 
                        field: "action", 
                        key: "e", 
                        title: "Action",
                        width: "",
                        align: "right",
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return (
                                <span>
                                    <el-button type="primary" icon="fas fa-edit"  on-click={() =>this.handleEdit(row)} size='mini' class="p-1"></el-button>
                                    <el-button type="danger" icon="el-icon-delete" on-click={() =>this.handleDelete(row)} size='mini' class="p-1"></el-button>
                                    
                                    &nbsp;
                                </span>
                            );
                        },
                    },
                ],

            }
        },

        created() {
            this.loadFilter();
            this.loadData(0);
        },


        methods: {
            
            handleRefresh(){
                this.loading = true;
                this.loadData(this.curent_groupe);
            },

            handleAddUser(){
                this.form = {};
                this.title = this.titleAdd;
                this.type= 1;
                this.showAdd = 1;
                this.tableCols = 7;
                
            },

            handleCloseAdd(){
                this.$swal({
                    title: this.$t('global_cancel'),
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d13737',
                    confirmButtonText: this.$t('yess')
                }).then((result) => {
                    if (result.value) {
                        this.showAdd = 0;
                        this.tableCols = 12;
                    }
                });
                
            },

            async loadData(group)
            {
                NProgress.start();
                this.loading = true;

                try {
                    
                    if (group == 0) {
                        const response = await axios.get('v1/xperf/crm/get/customers');
                        this.all_data = response.data;
                        this.tableData = this.all_data.slice(0);
                        this.loading = false;
                    } else {
                        let id = {id: group.value};
                        const response = await axios.post('v1/xperf/crm/get/customers_gp',id);
                        this.all_data = response.data[0].users;
                        this.tableData = this.all_data.slice(0);
                        this.loading = false;
                    }

                } 
                catch (error) {

                    this.loading = false;
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();
            },

            async loadFilter()
            {
                NProgress.start();

                try {

                    const response = await axios.get('v1/xperf/crm/get/groups');
                    let res = response.data;
                  
                    res.forEach(element => {

                        let row = {
                            label: element.title,
                            value: element.id
                        }
                        this.groupe_liste.push(row);
                    });

                    this.loading = false;
                } 
                catch (error) {
                    this.loading = false;
                    this.$notify({
                        type: 'error',
                        title: this.$t('global_error')
                    });
                }

                NProgress.done();
            },

            handleEdit(param){
                this.form = param;
                this.title = this.titleEdit;
                this.tableCols = 7;
                this.type= 2;
                this.showAdd = true;

            },

            handleDelete(param){
                this.$swal({
                    title: this.$t('global_delete_title'),
                    text:  this.$t('global_alert_continue'),
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d13737',
                    confirmButtonText:  this.$t('yess'),
                }).then((result) => {
                    if (result.value) {
                        this.delete(param);
                    }
                });
            },

            async delete(user) {

                NProgress.start();
                try {

                    const response = await axios.post('v1/xperf/setting/user/delete', user);
                    this.loadData(this.curent_groupe);
                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_delelte_succes')+"",
                        'success'
                    );
                } 
                catch (error) {

                    this.loading = false;
                    this.$swal(
                        ""+this.$t('global_alert_echec_title')+"",
                        ""+this.$t('global_alert_echec_text')+"",
                        'error'
                    );
                }

                NProgress.done();
            },

            // search
            search() {
                const searchValue = this.searchValue;
                this.tableData = this.all_data.filter(
                    (x) =>
                        !searchValue.length ||
                        x.name.toLowerCase().includes(searchValue.toLowerCase()),
                );
            },

            // search cancel
            searchCancel(closeFn) {
                closeFn();
            },

            // search cancel
            searchConfirm() {
                this.search();
            },

        },

        watch: {
            searchValue : function () {
                if (this.searchValue.length == 0) {
                    this.searchConfirm();
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

    .br-0 {
        border-radius: 0px !important;
    }
    .custom-name-filter {
        padding: 10px;

        .custom-name-filter-operation {
            cursor: pointer;

            margin: 10px 10px 0px 0;
            .name-filter-cancel,
            .name-filter-confirm {
                &:hover {
                    color: #1890ff;
                }
            }

            .name-filter-cancel {
                float: left;
            }

            .name-filter-confirm {
                float: right;
            }
        }
    }
    .table-pagination {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
    }

    .search {
  width: 100%;
  position: relative;
  display: flex;
}

    .searchTerm {
        width: 100%;
        border: 1px solid #007FFF;
        border-right: none;
        padding: 5px;
        height: auto;
        border-radius: 5px 0 0 5px;
        outline: none;
    }

    .searchTerm:focus{
    }

    .searchButton {
        width: 40px;
        height: auto;
        background: #007FFF;
        text-align: center;
        color: #fff;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        font-size: 20px;
    }

    /*Resize the wrap to see the search bar change!*/
    .wrap{
        width: 30%;
        transform: translate(-50%, -50%);
    }
</style>