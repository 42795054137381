<template>
    <div>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" >
                    <v-text-field class="purple-input" v-model="form.name" :label="$t('pages.crm.customers.sections.customers.add.name')"/>
                </v-col>
                <v-col cols="12" md="6" >
                    <v-text-field class="purple-input" v-model="form.surname" :label="$t('pages.crm.customers.sections.customers.add.surname')"/>
                </v-col>
                <v-col cols="12" md="4" >
                    <v-text-field class="purple-input" v-model="form.phone" :label="$t('pages.crm.customers.sections.customers.add.contact_1')"/>
                </v-col>
                <v-col cols="12" md="4" >
                    <v-text-field class="purple-input" v-model="form.phone_p" :label="$t('pages.crm.customers.sections.customers.add.contact_2')"/>
                </v-col>
                <v-col cols="12" md="4" >
                    <v-text-field class="purple-input" v-model="form.email" :label="$t('pages.crm.customers.sections.customers.add.email')"/>
                </v-col>
                <v-col cols="12" md="4" >
                    <v-text-field class="purple-input" v-model="form.quartier" :label="$t('pages.crm.customers.sections.customers.add.quater')"/>
                </v-col>
                <v-col cols="12" md="4">
                    <el-select v-model="form.sexe" :placeholder="$t('pages.crm.customers.sections.customers.add.sex')" class="mt-2">
                        <el-option v-for="item in sexes" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </v-col>
                <v-col cols="12" md="4">
                    <el-select v-model="form.ville" filterable allow-create :placeholder="$t('pages.crm.customers.sections.customers.add.town')" class="mt-2">
                        <el-option v-for="item in villes" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </v-col>
                <v-col cols="12" md="4">
                    <el-select v-model="form.function" filterable allow-create :placeholder="$t('pages.crm.customers.sections.customers.add.customers_type')">
                        <el-option v-for="item in type_customers" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </v-col>
                <v-col cols="12" md="8" class="text-right" >
                    <el-button type="primary" @click="handleSave()" style="border-radius: 0px"> <i class="fa fa-save mr-2"></i>{{$t('global_button_save')}}</el-button>
                </v-col>
                
            </v-row>
        </v-card-text>
    </div>
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    export default {

        components: {
        },

        props: ['form', 'type','loadData'],

        data() {
            return {
                search: '',
                loading: true,

                sexes: [{
                        value: 'M',
                        label:  this.$t('pages.crm.customers.sections.customers.add.label_sexe.male')
                    }, {
                        value: 'F',
                        label:  this.$t('pages.crm.customers.sections.customers.add.label_sexe.feminine')
                    }
                ],

                villes: [{
                    value: 'doua',
                    label:  this.$t('pages.crm.customers.sections.customers.add.label_town.douala')
                    }, {
                    value: 'yaou',
                    label:  this.$t('pages.crm.customers.sections.customers.add.label_town.yaounde')
                    }, {
                    value: 'bafo',
                    label:  this.$t('pages.crm.customers.sections.customers.add.label_town.bafoussam')
                    }
                ],
                type_customers: [
                    {
                        value: 'Pressing',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.pressing')
                    }, {
                        value: 'Hotel',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.hotel')
                    }, {
                        value: 'particular',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.particular')
                    }, {
                        value: 'unknow',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.unknow')
                    }
                ],
            }
        },

        created(){
        },
        methods: {

            handleEdit(index, row) {
                this.dialog = true;
                this.form = row;
            },

            handleSave() {
                if (this.type == 1) {
                    this.save();
                } else if(this.type == 2) {
                    this.update();
                }
            },

            async save() {
                
                NProgress.start();

                try {

                    
                    this.form.date_naiss = moment(new Date()).startOf('day').format('YYYY-MM-DD');
                    this.form.username = this.form.email;
                    this.form.type_id = 2;

                    console.log(this.form);
                    const response = await axios.post('v1/xperf/setting/user/create', this.form);
                    // this.loadData();
                    this.form = {};

                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'success'
                    );
                } 
                catch (error) {
                    this.$swal(
                        ""+this.$t('global_alert_echec_title')+"",
                        ""+this.$t('global_alert_echec_text')+"",
                        'error'
                    );
                }

                NProgress.done();

            },

            async update() {
                
                NProgress.start();

                try {

                    this.form.username = this.form.email;
                    const response = await axios.post('v1/xperf/setting/user/edit', this.form);
                    // this.loadData();
                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'success'
                    );
                } 
                catch (error) {
                    this.handleClose();
                     this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'warning'
                    );
                }

                NProgress.done();

            },
        },
    }
</script>

<style>
    
    element.style {
        overflow:hiden;
    }
</style>